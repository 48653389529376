import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { GtmHeadTag } from "../cookies/components/GtmHeadTag";

const ACCEPTED_COOKIE = "COOKIE_ACCEPTED";
const ADVERTISING_COOKIE = "COOKIE_ADVERTISING";
const ANALYTICS_COOKIE = "COOKIE_ANALYTICS";
const FUNCTIONAL_COOKIE = "COOKIE_FUNCTIONAL";

const defaultState = {
  accepted: false,
  advertising: false,
  analytics: true,
  functional: true,
};

export function useCookies() {
  const [cookies, setCookies] = useState(defaultState);

  useEffect(() => {
    // Analytics and functional cookies are always on
    setCookieValue(ANALYTICS_COOKIE, 1);
    setCookieValue(FUNCTIONAL_COOKIE, 1);
  }, []);

  const setAccepted = (value: any) => setCookieValue(ACCEPTED_COOKIE, value);
  const setAdvertising = (value: any) => {
    setCookieValue(ADVERTISING_COOKIE, value);
  };
  const setAnalytics = (value: any) => {
    setCookieValue(ANALYTICS_COOKIE, value);
  };

  return {
    ...cookies,
    setAccepted,
    setAdvertising,
    setAnalytics,
  };

  function setCookieValue(name: any, value: any) {
    Cookies.set(name, value, { expires: 365 });
    Cookies.set(name, value, { expires: 365, domain: "bigfive.maakjestap.nl" });
    setCookies(getCookieState());
  }

  function getCookieState() {
    return {
      accepted: Cookies.get(ACCEPTED_COOKIE) === "1",
      advertising: Cookies.get(ADVERTISING_COOKIE) === "1",
      analytics: Cookies.get(ANALYTICS_COOKIE) === "1",
      functional: Cookies.get(FUNCTIONAL_COOKIE) === "1",
    };
  }
}
