import { AnimatePresence } from "framer-motion";
import type { AppProps } from "next/app";
import Head from "next/head";
import { GtmHeadTag } from "../cookies/components/GtmHeadTag";
import { GTM_ID } from "../helpers/gtm";
import { AppContext } from "../helpers/useAppContext";
import { useCookies } from "../helpers/useCookies";
import "../styles/globals.css";

function MyApp({ Component, pageProps }: AppProps) {
  const cookies = useCookies();
  let analytics = cookies.analytics ? "granted" : "denied";
  let advertising = cookies.advertising ? "granted" : "denied";
  return (
    <AppContext.Provider value={{ cookies }}>
      <Head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicons/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicons/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicons/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicons/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicons/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#ffc40d" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="facebook-domain-verification"
          content="jt5fmirbiy0e90i1yt46gbyr9n9hrd"
        />
      </Head>

      <GtmHeadTag
        id={GTM_ID}
        ad_storage={advertising}
        analytics_storage={analytics}
      />
      <AnimatePresence mode="sync">
        <Component {...pageProps} />
      </AnimatePresence>
    </AppContext.Provider>
  );
}
export default MyApp;
