import Script from "next/script";
import { useCookies } from "../../helpers/useCookies";

type consent = string;
type gtm = {
  id: string | undefined;
  ad_storage?: consent;
  analytics_storage?: consent;
};

export const GtmHeadTag = ({
  id,
  ad_storage = "denied",
  analytics_storage = "granted",
}: gtm) => {
  const cookies = useCookies();
  let analytics = cookies.analytics ? "granted" : "denied";
  let advertising = cookies.advertising ? "granted" : "denied";
  return (
    <>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            // src: https://developers.google.com/tag-platform/devguides/consent#tag-manager
            // Define dataLayer and the gtag function.
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
        
            // Default ad_storage to 'denied'.
            gtag('consent', 'default', {
              'ad_storage': '${ad_storage}',
              'analytics_storage': '${analytics_storage}',
            });
          `,
        }}
      />
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${id}');
          `,
        }}
      />
    </>
  );
};
